import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()

export class InvoiceService {
    static invoiceTableData: any[];
    static invoiceHederDetails: any = {};
    static InvoiceDetails: any[] = [];
    static PurchaseOrderList: any[];
    static softcopyData: any;
    static tableType;
    static isPoBasedInvoice: boolean;
    fileUploadUrl: string = 'https://aider.applexus.com/api/v1/invoice-extraction'
    fileUploadResponse: any;
    
    static reviewInvoice: any = {
        headerDetails:{},
        lineItems:[]
    }

    constructor(private _httpClient: HttpClient) { }

    uploadInvoiceFile(file: any) {
        const formData = new FormData()
        formData.append('file', file)
        const headers = new HttpHeaders()
            .set('Client-Api', '64625492ba494631fcebd1c60dd49937')
            .set('x-auth-token', '12db91b6b3a94f89c93ded12d8386eb4')
            // .set('x-doc-schema', '10002')

        return this._httpClient.post(this.fileUploadUrl, formData, { headers });
    }

    getImage(guid){
        const url = `https://aider.applexus.com/api/v1/document-rawdata/${guid}`
        const headers = new HttpHeaders()
        .set('Client-Api', '64625492ba494631fcebd1c60dd49937')
        .set('x-auth-token', '12db91b6b3a94f89c93ded12d8386eb4')
        .set('x-doc-schema', '10002')

        return this._httpClient.get(url)

    }

    getInvoiceList() {
        return this._httpClient.get("assets/files/invoice.json");
    }
}